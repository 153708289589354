export enum FontPluginStyle {
  COLOR_HEX = 'color',
  COLOR_NAME = 'color-name', // CUSTOM propety
  COLOR_TINT = 'color-tint', // CUSTOM propety
  FONT_FAMILY = 'font-family',
  CHARACTER_STYLE_NAME = 'character-style-name', // CUSTOM propety
  FONT_SIZE = 'font-size',
  FONT_WEIGHT = 'font-weight',
  FONT_STYLE = 'font-style',
}
