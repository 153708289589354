import Draft from 'draft-js';
import { selectAbbreviationsData } from 'modules/Abbreviations/store/selectors';
import {
  Features,
  UseDraftjsEditorReturnType,
  useDraftjsEditor,
} from 'modules/draftjs';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Constants from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import { getLastOperation } from '../utils/editor';
import { Styles } from '../utils/styles';
import { BrandProps } from './useBrandProps';

const draftjsFeatures = [
  Features.APPLY_HACKS,
  Features.ABBREVIATIONS,
  Features.DECORATORS,
  Features.BLOCK,
  Features.BULLET,
  Features.FONT_COLOR,
  Features.INLINE_STYLE_EXTENDED,
  Features.APPLY_BRANDSTYLE_FOR_EXTENDED_INLINE_STYLE,
  Features.LINK,
] as const;

type DraftjsHook = UseDraftjsEditorReturnType<typeof draftjsFeatures>;

export type TextEditorProps = DraftjsHook['props'];

export type TextEditorSetters = {
  abbreviationID: (id: string | undefined) => void;
  blockLineHeight: (value: Constants.TextLineHeightValue) => void;
  blockType: (type: Constants.TextHorizontalAlignmentType | undefined) => void;
  bulletColor: (color: Models.BrandColorMap) => void;
  fontColor: (color: Models.BrandColorMap | undefined, editorState?: Draft.EditorState) => void;
  fontFamily: (font: Models.BrandFontMap, characterStyle: Models.CharacterStyleMap) => void;
  fontSize: (value: number) => void;
  inlineStyle: (style: string) => void; // will toggle one of inline styles
  link: (value: string | undefined) => void;
  scriptStyle: (style: Constants.ScriptType) => void;
  textNoWrap: () => void;
};

export type UseEditorOtherState = {
  operations: Models.DraftEditorOperations;
  activeFontFamily: Models.FontFamily; // TODO: not clear for what it is used
};

export type EditorHook = {
  editorRef: DraftjsHook['editorRef'];
  editorState: Draft.EditorState;
  operations: Models.DraftEditorOperation[];
  activeFontFamily: Models.FontFamily;
  hasTextContent: () => boolean;
  getEditorState: () => Draft.EditorState;
  setEditorState: (state: Draft.EditorState) => void;
  setOperations: (operations: Models.DraftEditorOperations) => void;
  addOperation: (operation: Models.DraftEditorOperation) => void;
  setActiveFontFamily: (value: Models.FontFamily) => void;
  setEditorStateAndOperations: (editorState: Draft.EditorState) => void;
  onEditorChange: (editorState: Draft.EditorState) => void;
  returnFocusToEditor: () => void;
  props: TextEditorProps;
  setters: TextEditorSetters;
};

type Options = {
  projectType: Constants.ProjectType;
  editMode: boolean;
};

export default function useEditor(
  options: Options,
  brandProps: BrandProps,
  brandStyle: Styles['brandStyle'],
  setBrandStyleChanged: (value?: boolean) => void,
  onInit: () => Draft.EditorState,
  onOtherInit: () => UseEditorOtherState,
  onBeforeChange?: () => void,
): EditorHook {
  const textAbbreviations = useSelector(selectAbbreviationsData);
  const textAbbreviationsRef = useRef(textAbbreviations);
  // we have to manually set ref as it's still old on first render
  textAbbreviationsRef.current = textAbbreviations;

  const draftjs = useDraftjsEditor(
    onInit,
    {
      editMode: options.editMode,
      projectType: options.projectType,
      colors: brandProps.colors,
      fonts: brandProps.fonts,
      brandStyle,
      abbreviationsData: textAbbreviations,
    },
    draftjsFeatures,
  );
  const {
    setEditorState,
    resetEditorState,
    onEditorChange,
    returnFocusToEditor,
  } = draftjs;
  const editorStateRef = useRef(draftjs.editorState);
  editorStateRef.current = draftjs.editorState;

  const [stateOther, setStateOther] = useState<UseEditorOtherState>(onOtherInit);
  const stateActiveFontFamily = stateOther.activeFontFamily;
  const stateOtherRef = useRef(stateOther);
  stateOtherRef.current = stateOther;

  const brandPropsRef = useRef(brandProps);
  brandPropsRef.current = brandProps;

  const optionsRef = useRef(options);
  optionsRef.current = options;

  const setOperations = useCallback(
    (operations: Models.DraftEditorOperations) => setStateOther(prev => ({ ...prev, operations })),
    [],
  );
  const addOperation = useCallback(
    (operation: Models.DraftEditorOperation) => setStateOther(
      prev => ({ ...prev, operations: prev.operations.concat(operation) }),
    ),
    [],
  );
  const addOperationFromEditorState = useCallback((state: Draft.EditorState) => {
    const operation = getLastOperation(state, editorStateRef.current);
    if (operation) {
      addOperation(operation);
    }
  }, [addOperation]);

  const setActiveFontFamily = useCallback(
    (activeFontFamily: Models.FontFamily) => setStateOther(prev => ({ ...prev, activeFontFamily })),
    [],
  );

  const hasTextContent = useCallback(() => {
    return editorStateRef.current.getCurrentContent().getPlainText().trim().length !== 0;
  }, []);

  const setEditorStateAndOperations = useCallback((editorState: Draft.EditorState): void => {
    addOperationFromEditorState(editorState);
    resetEditorState(editorState);
  }, [resetEditorState, addOperationFromEditorState]);

  const onEditorChangeModified = useCallback((editorState: Draft.EditorState): void => {
    addOperationFromEditorState(editorState);
    onEditorChange(editorState);
  }, [onEditorChange, addOperationFromEditorState]);

  const { setters, props } = draftjs;
  const modifiedProps = useMemo(() => ({
    ...props,
    fontFamily: stateActiveFontFamily,
  }), [props, stateActiveFontFamily]);

  const modifiedSetters = useMemo<TextEditorSetters>(() => ({
    ...setters,
    abbreviationID: (value): void => {
      setters.abbreviationID(value, onBeforeChange);
      addOperation({ type: 'apply-entity' } as unknown as Models.DraftEditorOperation);
    },
    blockLineHeight: (value): void => {
      setters.blockLineHeight(value);
      setBrandStyleChanged();
    },
    blockType: (value): void => {
      setters.blockType(value);
      setBrandStyleChanged();
    },
    bulletColor: (brandColor: Models.BrandColorMap): void => {
      setters.bulletColor(brandColor);
      setBrandStyleChanged();
      addOperation({ type: Constants.EditorChangeType.SET_BULLET_COLOR });
    },
    // IN-PROGRESS: editorState should be removed after refactoring useUpdate hook
    fontColor: (brandColor, currentEditorState?: Draft.EditorState): void => {
      setters.fontColor(brandColor, currentEditorState);
      setBrandStyleChanged();
    },
    fontFamily: (font: Models.BrandFontMap, characterStyle: Models.CharacterStyleMap): void => {
      setters.fontFamily(font, characterStyle);
      setBrandStyleChanged();
    },
    fontSize: (size: number): void => {
      const { projectType } = optionsRef.current;
      const { allowCustomRangeFontSizeSelection } = Constants.ProjectsConfig[projectType];
      if (allowCustomRangeFontSizeSelection) {
        setters.fontSize(size);
      } else {
        let state = editorStateRef.current;
        const selection = editorUtils.selectBlocks(state);
        state = Draft.EditorState.acceptSelection(
          state,
          selection.merge({ hasFocus: true }) as Draft.SelectionState,
        );
        setEditorState(editorUtils.toggleFontSize(state, size));
      }
      setBrandStyleChanged();
      returnFocusToEditor();
    },
    link: value => setters.link(value, onBeforeChange),
  }), [
    setters, setEditorState, addOperation,
    returnFocusToEditor, setBrandStyleChanged, onBeforeChange,
  ]);

  return {
    editorRef: draftjs.editorRef,
    editorState: draftjs.editorState,
    operations: stateOther.operations,
    activeFontFamily: stateActiveFontFamily,
    hasTextContent,
    getEditorState: draftjs.getEditorState,
    setEditorState: draftjs.setEditorState,
    setOperations,
    addOperation,
    setActiveFontFamily,
    setEditorStateAndOperations,
    onEditorChange: onEditorChangeModified,
    returnFocusToEditor: draftjs.returnFocusToEditor,
    props: modifiedProps,
    setters: modifiedSetters,
  };
}
