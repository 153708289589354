import { $getSelectionStyleValueForProperty, $patchStyleText } from '@lexical/selection';
import { $getRoot, $getSelection, $isRangeSelection, BaseSelection } from 'lexical';
import { $isCustomTextNode, CustomTextNode } from 'modules/Lexical/nodes/CustomTextNode';
import { getBrandColorProperties } from 'components/ArtboardAssets/Text/utils/brand';
import * as Constants from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import { fromPx } from 'utils/toPx';
import { FontPluginStyle } from './style';

export function $getFontBranColor(colors: Models.BrandColorsList): Models.BrandColorMap | undefined {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return undefined;
  }

  const color = selection.getNodes().reduce((value, node) => {
    if ($isCustomTextNode(node)) {
      const nodeValue = node.getColor();
      if (value === null) {
        return nodeValue;
      }

      if (value !== nodeValue) {
        return undefined;
      }
    }

    return value;
  }, null);

  if (!color) {
    return undefined;
  }

  return editorUtils.getBrandColor(
    colors,
    color.name ?? color.HEX,
    color.tint,
  );
}

export function $getFontFamily(): Models.FontFamily | undefined {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return undefined;
  }

  const fontFamily = $getSelectionStyleValueForProperty(selection, FontPluginStyle.FONT_FAMILY) || Constants.DefaultCustomStyle.FONT_FAMILY;
  const characterStyleName = $getSelectionStyleValueForProperty(selection, FontPluginStyle.CHARACTER_STYLE_NAME) || undefined;

  return { fontFamily, characterStyleName };
}

export function $getFontSize(): number | undefined {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return undefined;
  }
  const value = $getSelectionStyleValueForProperty(selection, FontPluginStyle.FONT_SIZE);

  return value ? fromPx(value) : Constants.DefaultCustomStyle.FONT_SIZE;
}

export function $getInlineStyle(): Constants.InlineStyle[] {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return [];
  }
  const result: Constants.InlineStyle[] = [];

  const fontStyle = $getSelectionStyleValueForProperty(selection, FontPluginStyle.FONT_STYLE);
  if (fontStyle === 'italic') {
    result.push(Constants.InlineStyle.ITALIC);
  }

  const fontWeight = $getSelectionStyleValueForProperty(selection, FontPluginStyle.FONT_WEIGHT);
  if (fontWeight === 'bold' || Number(fontWeight) >= 700) {
    result.push(Constants.InlineStyle.BOLD);
  }

  if (selection.hasFormat('underline')) {
    result.push(Constants.InlineStyle.UNDERLINE);
  }

  return result;
}

export function $updateBrandStyleAndColors(
  brandStyle: Models.BrandStyleMap | undefined,
  colors: Models.BrandColorsList,
): void {
  const root = $getRoot();
  root.getAllTextNodes().forEach((node: CustomTextNode) => {
    const color = node.getColor();
    if (!color) {
      return;
    }
    const brandColor = editorUtils.getBrandColor(
      colors,
      color.name ?? color.HEX,
      color.tint,
    );
    const { colorHEX, colorName, colorTint } = getBrandColorProperties(
      brandColor,
      brandStyle,
      colors,
    );
    if (colorHEX === color.HEX && colorName === color.name && colorTint === color.tint) {
      return;
    }
    node.setColor({ HEX: colorHEX, name: colorName, tint: colorTint });
  });
}

export function $patchFontWeight(selection: BaseSelection, isBold: boolean): void {
  $patchStyleText(selection, {
    [FontPluginStyle.FONT_WEIGHT]: isBold ? '700' : '400',
  });
}

export function $patchFontStyle(selection: BaseSelection, isNormal: boolean): void {
  $patchStyleText(selection, {
    [FontPluginStyle.FONT_STYLE]: isNormal ? 'normal' : 'italic',
  });
}
