import _ from 'lodash';

export function toPx(value: number | string, precision = 0): string {
  if (_.isNil(value)) {
    return value;
  }

  return `${_.round(parseFloat(String(value)), precision)}px`;
}

// 16px => 16
// 16 => 16
// 2rem => NaN
export function fromPx(value: string): number {
  const mathes = /^([\d.]+)(px)?$/.exec(value);
  if (!mathes) {
    return NaN;
  }

  return parseFloat(mathes[1]);
}
